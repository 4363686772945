<template>
    <div class="main-wrap">
        <div class="header-wrapper py-2 pb-0 shadow-sm layer-after scroll-header pos-fixed bg-transparent bg-blur">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 navbar pt-0 pb-0">
                        <router-link :to="{name: 'BrowseNew'}"><h1 class="fredoka-font ls-3 fw-700 text-current-eling font-xxxl"><img :src="'/images/logo-1-new.png'" width="40px" alt=""> Elingway</h1></router-link>
                        <button @click="() => this.isNavShow = !this.isNavShow" class="navbar-toggler" v-bind:class="{'collapsed': isNavShow}" type="button" :aria-expanded="isNavShow" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" :class="{'show' : isNavShow}" id="navbarNavDropdown">
                            <ul class="navbar-nav nav-menu float-none text-center" :class="textBrowse">
                                <li class="nav-item dropdown">
                                    <a class="nav-text nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">All Services <i class="ti-angle-down"></i></a>
                                    <div class="dropdown-menu">
                                        <router-link class="dropdown-item text-black" to="/lms">LMS</router-link>
                                        <router-link class="dropdown-item text-black" to="/library">Library</router-link>
                                        <router-link class="dropdown-item text-black" to="/cloud">Cloud</router-link>
                                        <router-link class="dropdown-item text-black" to="/community">Community</router-link>
                                        <router-link class="dropdown-item text-black" to="/thread">Thread</router-link>
                                    </div>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-text nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Test Programs <i class="ti-angle-down"></i></a>
                                    <div class="dropdown-menu">
                                        <router-link class="dropdown-item text-black" to="/toefl-itp">TOEFL ITP</router-link>
                                        <router-link class="dropdown-item text-black" to="/toefl-ibt">TOEFL iBT</router-link>
                                        <router-link class="dropdown-item text-black" to="/toeic">TOEIC</router-link>
                                        <router-link class="dropdown-item text-black" to="/ielts">IELTS</router-link>
                                    </div>
                                </li>
                                <li class="nav-item"><router-link class="nav-text nav-link" :to="{name: 'BlogBrowseNew'}">Blog</router-link></li>
                                <li class="nav-item"><router-link class="nav-text nav-link" :to="{name: 'EventsBrowseNew'}">Events</router-link></li>
                                <li class="nav-item"><router-link class="nav-text nav-link" :to="{name: 'AboutBrowseNew'}">About us</router-link></li>
                                <li v-if="isAuth" class="nav-item only-mobile"><router-link class="nav-text nav-link" to="/get-started">Home</router-link></li>
                                <li v-if="isAuth" class="nav-item only-mobile"><a class="nav-text nav-link text-danger fw-500" href="javascript:void(0)" @click="onLogout">Sign Out</a></li>
                                <li v-if="isGuest" class="nav-item only-mobile"><router-link class="nav-text nav-link" to="/login">Login</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 d-none d-lg-block ">
                        <div v-if="isAuth" class="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul class="navbar-nav nav-menu float-none text-center p-2">
                                <li class="nav-item dropdown"><a class="nav-text nav-link dropdown-toggle button-login-text" :class="textBrowse" href="javascript:void">{{user.name}} <i class="ti-angle-down "></i></a>
                                <div class="dropdown-menu float-left">
                                    <router-link class="dropdown-item text-black" to="/get-started">Home</router-link>
                                    <a class="dropdown-item text-black" href="javascript:void(0)" @click="onLogout">Log Out</a>
                                </div>
                            </li>
                            </ul>
                        </div>
                        <div v-if="isGuest" class="pt-lg--8px text-right">
                            <router-link to="/login" class="button-login-text" :class="textBrowse">Log In</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 p-0 m-0">
                <router-view></router-view>
            </div>
        </div>
        <div class="footer-wrapper mt-0">
            <div class="w-100 pb-7">
                <div class="row justify-content-center">
                    <div class="col-10 mb-4">
                        <div class="row justify-content-between">
                            <div class="col-lg-3 mb-lg-0 mb-5">
                                <div class="about-box">
                                    <p class="w-100 font-md text-current fw-600 mt-3">ABOUT US</p>
                                    <img class="top-left" :src="'/images/bg/sec1-top-left-border.svg'" width="80px" alt="">
                                    <img class="bottom-right" :src="'/images/bg/sec1-bottom-right-border.svg'" width="80px" alt="">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="w-100">
                                                <router-link :to="{name: 'AboutBrowseNew'}" class="w-100 text-black fw-600 font-xs">Programs</router-link>
                                            </p>
                                            <p class="w-100">
                                                <router-link to="/term-of-condition" class="w-100 text-black fw-600 font-xs">Privacy Policy</router-link>
                                            </p>
                                            <p class="w-100">
                                                <router-link :to="{name: 'AboutBrowseNew'}" class="w-100 text-black fw-600 font-xs">FAQ</router-link>
                                            </p>
                                            <p class="w-100">
                                                <router-link to="/term-of-condition" class="w-100 text-black fw-600 font-xs">Terms and Conditions</router-link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <router-link to="/"><h1 class="fredoka-font ls-3 fw-700 text-current-eling font-xxl">Elingway <span class="d-block font-xsssss ls-1 text-grey-500 open-font ">Learning Any Language Differently</span></h1></router-link>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-left">
                                        <p class="w-100 text-black fw-600 font-xs">PT Esa Lingua Internasional</p>
                                        <p class="w-100 text-black fw-600 font-xs">Jl. Terusan Sekar Kemuning Blok B No. 1, Karyamulya, Kec. Kesambi, Kota Cirebon 45135</p>
                                        <p class="w-100 text-black fw-600 font-xs">Follow us on</p>
                                        <ul class="list-inline">
                                            <li class="list-inline-item mr-3"><a href="https://www.instagram.com/elingway.id" target="_blank"><i class="ti-instagram font-xxl text-curent"></i></a></li>
                                            <li class="list-inline-item mr-3"><a href="https://twitter.com/Elingway_id/" target="_blank"><i class="ti-twitter-alt font-xxl text-curent"></i></a></li>
                                            <li class="list-inline-item"><a href="https://facebook.com/elingway.id" target="_blank"><i class="ti-facebook font-xxl text-curent"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-10">
                        <p class="copyright-text text-black fw-600 w-100 text-center font-xss"> &copy; {{ year }} Copyright. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
@media only screen and (min-width: 992px) {
    .only-mobile {
        display: none !important;
    }
}
@media only screen and (max-width: 992px) {
    .navbar-collapse {
        height: 400px;
        overflow-y: auto;
    }
}
@media only screen and (max-width: 1200px) {
    .nav-text {
        font-size: 12px !important;
    }
}
@media only screen and (min-width: 1201px) {
    .nav-text {
        font-size: 20px !important;
    }
}
.list-inline-item a i {
    color: #fbaf03 !important;
}
.button-login-text {
    font-size: 19px;
    line-height: 50px;
    font-weight: 500;
    letter-spacing: 0.2px;
    transition: all 0.4s ease;
}
.button-login-text:hover {
    color: #fbaf03 !important ;
}
.about-box {
    width: fit-content;
    height: fit-content;
    padding: 2rem;
}
.about-box .top-left {
    position: absolute;
    top: 0px;
    left: 0px;
}
.about-box .bottom-right {
    position: absolute;
    bottom: 0px;
    right: 0px;
}
.text-browse {
    color: #fff !important;
}
.text-browse .nav-text{
    color: #fff !important;
}
.router-link-active {
  color: var(--theme-color) !important;
}

.dropdown-menu {
    top: 80% !important;
}

.dropdown-menu a {
    padding: 0 12px !important;
}
.dropdown-menu a:hover {
    color: var(--theme-color) !important;
}
</style>
<script>
import axios from 'axios'
export default ({
    name: 'GuestLayout',
    data() {
        return {
            routeName : this.$route.name,
            textBrowse: 'text-white',
            isNavShow: false,
            isAuth: false,
            isGuest: false,
            user: {},
            year: new Date().getFullYear()
        }
    },
    created(){
        if(this.routeName == 'BrowseNew') {
            window.addEventListener('scroll', this.handleScroll);
        } else if(this.routeName == 'AboutBrowseNew') {
            window.addEventListener('scroll', this.handleScrollAbout);
        } else {
            this.textBrowse = 'text-black'
        }
        this.getUser()
    },
    destroyed () {
        if(this.routeName == 'BrowseNew') {
            window.removeEventListener('scroll', this.handleScroll);
        }
    },
    methods:{
        handleScroll () {
            let y = (window.scrollY || window.pageYOffset) / 150
            if(y > 6.8) {
                this.textBrowse = 'text-black'
            } else {
                this.textBrowse = 'text-white'
            }
        },
        handleScrollAbout () {
            let y = (window.scrollY || window.pageYOffset) / 150
            if(y > 1.86) {
                this.textBrowse = 'text-black'
            } else {
                this.textBrowse = 'text-white'
            }
        },
        async getUser() {
            if (localStorage.token) {
                await axios.get(`${process.env.VUE_APP_URL_API}/user`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.user = res.data
                    this.isAuth = true
                }).catch(() => {
                    this.isGuest = true
                })
            } else {
                this.isGuest = true
            }
        },
        async onLogout() {
            await axios.get(`${process.env.VUE_APP_URL_API}/logout`, {
            headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(() => {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                this.isGuest = true
                this.isAuth = false
                this.user = {}
            }).catch((err) => console.error(err))
        },
    }
})
</script>
